import reactDom, { Component } from 'react';
import React from 'react';
import { Button, Select } from 'semantic-ui-react';

class Recherche extends Component {
    state = {
        crypto: this.props.crypto,
        monnaie: this.props.monnaie
    }

    onCryptoChange = (e, data) => {
        this.setState({ crypto: data.value })
    }

    onMonnaieChange = (e, data) => {
        this.setState({ monnaie: data.value })
    }

    render() {

   
        const optionsCrypto = [
            { value: "usd", key: "usd", text: "US" },
            { value: "bitcoin", key: "bitcoin", text: "Bitcoin" },
            { value: "ethereum", key: "ethereum", text: "Ethereum" },
            { value: "cardano", key: "cardano", text: "Cardano" }
        ];

        const optionsMonnaie = [
            { value: "cad", key: "cad", text: "Canadien" },
            { value: "usd", key: "usd", text: "US" },
            { value: "eur", key: "eur", text: "Euro" },
            { value: "mxn", key: "mxn", text: "Peso Mexicain" },
            { value: "cop", key: "cop", text: "Peso Colombien" },
            { value: "cup", key: "cup", text: "Peso Cucain" }
        ]
        
        return (
            <div className='ui container center aligned'>
                <div className="ui segment">
                    <h1>Conversion de monnaie</h1>
                        
                    <Select onChange={this.onCryptoChange} placeholder="De" options={optionsCrypto} value={this.state.crypto} />
                    <Select onChange={this.onMonnaieChange} placeholder="Vers" options={optionsMonnaie} value={this.state.monnaie} /><br /><br />
                    <Button onClick={() => this.props.onSearch(this.state.crypto, this.state.monnaie)} primary>Convertir</Button>
                    <Button onClick={() => this.props.onEmpty()} secondary>Vider les champs</Button>
                </div>
            </div>
        )
    }
}

export default Recherche;