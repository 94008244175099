import React, { Component } from "react";
import { Message } from "semantic-ui-react";
import Recherche from "./components/Recherche";

class App extends Component {
  state = {
    data: '',
    error: '',
    crypto: '',
    monnaie: '',
    resultat: ''
  }

  onSearch = async (crypto, monnaie) => {
    if (crypto && monnaie) {
      try {
        let response = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${crypto}&vs_currencies=${monnaie}`);
        let donnees = await response.json();
        this.setState({
          data: donnees,
          error: '',
          crypto: crypto,
          monnaie: monnaie,
          resultat: Object.values(Object.values(donnees)[0])[0]
        })
      }
      catch (e) {
        this.setState({ error: "Erreur lors de la recherche" });
      }
    }
    else {
      this.setState({ error: "Les deux champs doivent contenir une valeur" });
    }
  }

  onEmpty = () => {
    this.setState({
      data: '',
      error: '',
      crypto: '',
      monnaie: '',
      resultat: ''}
      )
  }
  
  render() {
    return (
      <div className="App">

        <Recherche onSearch={this.onSearch} onEmpty={this.onEmpty} />
        {this.state.error ? <Message warning>Erreur:{this.state.error}</Message> : undefined}
        {this.state.data ? <Message warning> 1 {this.state.crypto} = {this.state.resultat} { this.state.monnaie} </Message> : undefined}

      </div>
    );
  }
}

export default App;
